// ==========[ VERY LARGE DESKTOPS ]==========

@media (max-width: 1600px) {
  button#sidebar-toggle {
    left: 270px;
  }

  nav#sidebar {
    width: 270px;

    &.hidden {
      left: -270px;
    }
  }

  nav#top-bar {
    section {
      &#left {
        #top-bar-search {
          padding: 0px 20px;
          width: 280px;
        }
      }

      > ul {
        > li {
          &:first-of-type {
            margin-left: 5px;
          }

          &:last-of-type {
            margin-right: 5px;
          }
        }
      }
    }
  }

  main#content {
    padding-left: 270px;
  }
}

// ==========[ DESKTOPS ]==========

@media (max-width: 1300px) {
  button#sidebar-toggle {
    left: 250px;
  }

  nav#sidebar {
    width: 250px;

    a#logo-box {
      height: 150px;
    }

    ul {
      max-height: calc(100vh - 150px);

      li {
        a {
          min-height: 50px;
          height: auto;
          padding: 10px 25px;

          &.category-item {
            padding-left: 50px;
          }
        }
      }
    }

    &.hidden {
      left: -250px;
    }
  }

  nav#top-bar {
    section {
      &#left {
        #top-bar-search {
          padding: 0px 10px 0 25px;
          width: 250px;
        }
      }

      > ul {
        > li {
          &:first-of-type {
            margin-left: 5px;
          }

          &:last-of-type {
            margin-right: 5px;
          }

          > a {
            margin: 0 10px;
            font-size: 14px;
          }
        }
      }
    }
  }

  main#content {
    padding-left: 250px;
  }
}

// ==========[ DESKTOPS ]==========

@media (max-width: 1200px) {
  .uk-notification {
    &-top-right {
      top: 80px;
    }
  }
  input.custom-number-input {
    padding-left: 0;
  }
  main#content {
    padding-left: 0;
  }
}

// ==========[ TABLETS ]==========

@media (max-width: 960px) {
  .products-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: '. .';
  }

  .cart {
    &__footer {
      display: grid;
      grid-template-columns: 4fr 2fr;

      &__delivery {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: 'title' 'form';
      }

      &__totals{
        grid-template-columns: 1fr;
        grid-template-rows: repeat(8, 1fr);
        grid-template-areas: '.' '.' '.' '.' '.' '.' '.' '.';
      }

    }

    .column-reverse-md {
      flex-direction: column-reverse;
    }
  }

  .steps {
    .step {
      padding: 0px 20px 0px 40px;
      margin-right: 10px;

      &:first-of-type {
        padding: 0px 20px 0px 25px;
      }

      &:last-of-type {
        padding: 0px 25px 0px 40px;
      }
    }
  }
}

// ==========[ MOBILE DEVICES ]==========

@media (max-width: 640px) {
  .uk-offcanvas {
    &-bar {
      width: 100vw;
      left: -100vw;
    }
  }

  .login-wrapper {
    width: 100vw;
    height: 100vh;
    padding: 0px 30px;

    .login-logo {
      margin-bottom: 30px;
    }

    .login-box {
      width: 100%;
      padding: 25px;
    }
  }

  .products-grid {
    grid-template-columns: 1fr;
    gap: 40px 30px;
    grid-template-areas: '.';
  }

  .products-bar {
    width: 100%;
    flex-flow: column;

    > section {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &__sorting {
      width: 180px;
      height: 40px;
      border: 1px solid $lines;
      border-radius: 3px;
      background-color: transparent;
      padding-left: 20px;
      padding-right: 20px;

      font-family: 'sofia-pro', sans-serif;
      font-size: 14px;
      line-height: 21px;
      color: $black;
    }

    &__toggle {
      color: $primary;

      &:hover {
        color: $black;
      }
    }
  }

  .products-column {
    grid-template-columns: 1fr;
    gap: 40px 30px;
    grid-template-areas: '.';
  }

  .product-gallery {
    &__nav {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }
  }

  .cart {
    &__footer {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, auto);
      gap: 0px 0px;
      grid-template-areas: '.' '.';
      margin-top: 80px;

      &__delivery {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, auto);
        gap: 0px 0px;
        grid-template-areas: 'title' 'form';
        align-items: end;
        margin-bottom: 30px;

        &--title{
          margin-bottom: 1.5em
        }
      }

      &__totals{
        grid-template-columns: 1fr;
        grid-template-rows: repeat(8, 1fr);
        gap: 0px 0px;
        grid-template-areas: '.' '.' '.' '.' '.' '.' '.' '.';

        align-items: end;

        > div {
          text-align: left;

          &:nth-child(even) {
            margin-bottom: 10px;
          }
        }
      }

    }
  }

  .steps {
    flex-flow: column;

    .step {
      width: 100%;
      margin-right: 0;
      border-bottom: 1px solid $lines;
      border-radius: 0;

      &:before,
      &:after {
        display: none;
      }

      &:first-of-type {
        padding: 0;
        border-radius: 3px 3px 0 0;
      }

      &:last-of-type {
        padding: 0;
        border-bottom: 0;
        border-radius: 0 0 3px 3px;
      }
    }
  }

  .uk-notification {
    &-top-right {
      top: 80px;
    }

    &-message {
      padding: 0.5em 2em;
    }
  }
}

/* INTERNET EXPLORER
-------------------------------------------------- */

@media all and (-ms-high-contrast: none) {
  .products-grid {
    display: -ms-grid;
    -ms-grid-columns: repeat(3, 1fr);
    -ms-grid-rows: 1fr;

    &--large {
      -ms-grid-columns: repeat(4, 1fr);
    }
  }

  .products-column {
    display: -ms-grid;
    -ms-grid-columns: repeat(2, 1fr);
    -ms-grid-rows: 1fr;

    &--large {
      -ms-grid-columns: repeat(3, 1fr);
    }
  }

  .product-gallery {
    &__nav {
      display: -ms-grid;
      -ms-grid-columns: repeat(4, 1fr);
      -ms-grid-rows: repeat(2, 1fr);
    }
  }

  .cart {
    &__footer {
      display: -ms-grid;
      -ms-grid-columns: 5fr 1fr;
      -ms-grid-rows: repeat(4, 1fr);
    }
  }
}
