/* UTILITIES
-------------------------------------------------- */
.sku {
  font-weight: bold;
  font-size: 13px;
  line-height: 21px;
  color: #829E8C;
  text-transform: uppercase;
}

.noselect {
  user-select: none;
}

.text--small {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
}
.text--white {
  color: #ffffff !important;
}
.text--muted {
  color: #f5f5f5 !important;
}

.border-bottom {
  border-bottom: 1px solid #e0e0e0;
}

/* FORMS
-------------------------------------------------- */
form.form-custom .form-custom__label {
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #222222;
}
form.form-custom .uk-input,
form.form-custom .uk-select,
form.form-custom .uk-textarea {
  height: 50px !important;
  background-color: transparent;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  padding: 0px 20px;
  font-family: "sofia-pro", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}
form.form-custom .uk-input::placeholder,
form.form-custom .uk-select::placeholder,
form.form-custom .uk-textarea::placeholder {
  color: #f5f5f5;
}
form.form-custom .uk-input:focus,
form.form-custom .uk-select:focus,
form.form-custom .uk-textarea:focus {
  border: 1px solid #829E8C;
  color: #829E8C;
  font-weight: 600;
  box-shadow: 0px 0px 0px 1px #829E8C;
}
form.form-custom .uk-input:focus::placeholder,
form.form-custom .uk-select:focus::placeholder,
form.form-custom .uk-textarea:focus::placeholder {
  font-weight: 600;
}
form.form-custom .uk-input:disabled,
form.form-custom .uk-select:disabled,
form.form-custom .uk-textarea:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}
form.form-custom .uk-input.uk-form-danger,
form.form-custom .uk-select.uk-form-danger,
form.form-custom .uk-textarea.uk-form-danger {
  border: 1px solid #ff2424;
  font-weight: bold;
  color: #ff2424;
  box-shadow: 0px 0px 0px 1px #ff2424;
}
form.form-custom .uk-input.uk-form-danger::placeholder,
form.form-custom .uk-select.uk-form-danger::placeholder,
form.form-custom .uk-textarea.uk-form-danger::placeholder {
  color: #ff2424;
  font-weight: bold;
}
form.form-custom .uk-textarea {
  height: auto !important;
  padding: 20px;
}
form.form-custom .date-input {
  background-image: url("/assets/images/icons/calendar.svg");
  background-repeat: no-repeat;
  background-size: 22px;
  background-position: right 20px center;
}
form.form-custom .uk-checkbox {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #e0e0e0;
  background-color: transparent;
  margin-right: 10px;
}
form.form-custom .uk-checkbox:checked {
  background-color: #829E8C;
  border: 1px solid #829E8C;
}
form.form-custom .uk-radio {
  width: 20px;
  height: 20px;
  border: 1px solid #e0e0e0;
  background-color: transparent;
  margin-right: 10px;
}
form.form-custom .uk-radio:checked {
  background-color: #829E8C;
  border: 1px solid #829E8C;
  background-size: 30px;
}
form.form-custom .form-uploader {
  border: 1px dashed #e0e0e0;
  background-color: transparent;
  min-height: 100px;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #f5f5f5;
}
form.form-custom .form-uploader .uk-link {
  font-weight: 300;
  text-decoration: underline;
  cursor: pointer;
}
form.form-custom .form-uploader .uk-link:hover {
  color: #71917c;
  text-decoration: none;
}
form.form-custom .form-uploader:hover {
  background-color: rgba(245, 247, 246, 0.5);
  color: #829E8C;
  border: 1px dashed #829E8C;
}
form.form-custom .search-input {
  background-image: url("/assets/images/icons/loupe.svg");
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: right 20px center;
  padding-right: 45px;
}

select.custom-select {
  width: 150px;
  height: 40px;
  background-color: transparent;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  padding: 0px 10px;
  font-family: "sofia-pro", sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #222222;
}
select.custom-select:focus {
  border: 1px solid #829E8C;
  outline: none;
}

input.custom-number-input {
  width: 60px;
  height: 50px;
  border: 1px solid #e0e0e0;
  background-color: transparent;
  border-radius: 3px;
  text-align: center;
  font-family: "sofia-pro";
  font-size: 14px;
  line-height: 21px;
  color: #222222;
  padding-left: 0.9em;
}
input.custom-number-input:focus {
  border: 1px solid #829E8C;
  outline: none;
}

/* LINKS & BUTTONS
-------------------------------------------------- */
.uk-link {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #829E8C;
}
.uk-link:hover {
  text-decoration: none;
  color: #688572;
}

.uk-button {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 20px 16px;
  border-radius: 3px;
}
.uk-button-small {
  padding: 0 15px;
  line-height: 30px;
  font-size: 0.875rem;
}

.uk-button-primary {
  background-color: #829E8C;
  color: #ffffff;
}
.uk-button-primary:hover {
  background-color: #688572;
}

.uk-button-secondary {
  background-color: #f5f7f6;
  color: #829E8C;
}
.uk-button-secondary:hover {
  background-color: #688572;
  color: #ffffff;
}

.uk-button-success {
  background-color: #1eaf3a;
  color: #ffffff;
  display: flex;
  align-items: center;
}
.uk-button-success .uk-svg {
  color: #ffffff;
  fill: #ffffff;
  margin-top: 3px;
}
.uk-button-success .uk-svg * {
  fill: #ffffff;
}
.uk-button-success:hover {
  background-color: #1a9531;
}

.uk-button-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  background-color: transparent;
  border: 1px solid #e0e0e0;
  font-weight: 300;
  color: #222222;
  transition: border 0.15s ease-in-out;
}
.uk-button-dropdown i,
.uk-button-dropdown svg {
  font-size: 12px;
  color: #f5f5f5;
  transition: color 0.15s ease-in-out;
}
.uk-button-dropdown:hover {
  border: 1px solid #829E8C;
}
.uk-button-dropdown:hover i,
.uk-button-dropdown:hover svg {
  color: #829E8C;
}

.uk-background-white {
  background-color: #ffffff;
}

.button__cart {
  width: 50px;
  height: 50px;
  background-color: #1eaf3a;
  color: #ffffff;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
}
.button__cart .uk-svg {
  color: #ffffff;
  fill: #ffffff;
}
.button__cart .uk-svg * {
  fill: #ffffff;
}
.button__cart:focus {
  outline: none;
}
.button__cart:hover {
  background-color: #1a9531;
  text-decoration: none;
}
.button__back {
  color: #f5f5f5;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}
.button__back:hover {
  color: #829E8C;
  text-decoration: none;
}
.button__delete {
  background-color: transparent;
  border: 0;
  font-size: 18px;
  color: #e0e0e0;
}

/* CART DROPDOWN
-------------------------------------------------- */
.cart-dropdown {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
  width: 390px;
  padding: 30px;
}
.cart-dropdown > ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.cart-dropdown > ul li {
  display: flex;
  flex-flow: column;
  padding: 20px 0;
  border-bottom: 1px solid #e0e0e0;
}

/* SETTINGS DROPDOWN
-------------------------------------------------- */
.settings-dropdown {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
  width: 260px;
  padding: 0;
}
.settings-dropdown--small {
  min-width: 84px;
  width: 84px !important;
}
.settings-dropdown ul {
  display: flex;
  flex-flow: column;
}
.settings-dropdown ul li a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  padding: 0px 30px;
  border-bottom: 1px solid #e0e0e0;
  color: #222222;
}
.settings-dropdown ul li a:hover {
  color: #829E8C;
  background-color: #f5f7f6;
}
.settings-dropdown ul li:last-of-type a {
  border-bottom: 0;
}

/* MODALS
-------------------------------------------------- */
.uk-modal {
  background-color: rgba(245, 247, 246, 0.95);
}
.uk-modal .uk-modal-dialog {
  background-color: #ffffff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  width: 65vw;
  display: flex;
  position: relative;
}
.uk-modal .uk-modal-dialog > form {
  width: 100%;
}
.uk-modal .uk-modal-dialog .image {
  position: absolute;
  top: 0;
  right: 66.66%;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.uk-modal .uk-modal-dialog .content {
  padding: 60px;
}
.uk-modal .uk-modal-dialog #closer {
  border: 0;
  background-color: transparent;
  position: absolute;
  right: 60px;
  top: 60px;
  font-size: 21px;
  color: #e0e0e0;
  cursor: pointer;
}
.uk-modal .uk-modal-dialog #closer:hover {
  color: #829E8C;
}
.uk-modal .uk-modal-dialog #closer:focus {
  outline: none;
}

/* LISTS
-------------------------------------------------- */
ul.checklist {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
ul.checklist li {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 16px;
}
ul.checklist li > i,
ul.checklist li > svg {
  font-size: 21px;
  flex: 0 0 21px;
  margin-right: 15px;
  color: #1eaf3a;
}
ul.checklist li p {
  margin: -2px 0 0 0;
}

/* IMAGES
-------------------------------------------------- */
.image-block {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 75%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.image-block--wide {
  padding-top: 56%;
}

.button-icon {
  width: 50px;
  height: 50px;
  border-radius: 9999px;
  background-color: #829E8C;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
.button-icon--large {
  width: 80px;
  height: 80px;
  font-size: 18px;
}

/* CONTAINERS
-------------------------------------------------- */
.uk-container {
  transition: max-width 0.6s cubic-bezier(0.65, 0, 0.35, 1);
}

/* TABLES
-------------------------------------------------- */
.uk-table.uk-table-hover > tr:hover,
.uk-table.uk-table-hover tbody tr:hover {
  background-color: #f5f7f6;
  cursor: pointer;
}
.uk-table.uk-table-hover > tr:hover *,
.uk-table.uk-table-hover tbody tr:hover * {
  color: #829E8C;
  font-weight: 600;
}

.action {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #222222;
}
.action:hover {
  color: #829E8C;
}

/* ACCORDIONS
-------------------------------------------------- */
.questions-accordion li {
  margin-bottom: 10px;
  margin-top: 0 !important;
}
.questions-accordion li a.uk-accordion-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 14px 30px;
  background-color: #f8f8f8;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
}
.questions-accordion li a.uk-accordion-title i,
.questions-accordion li a.uk-accordion-title svg {
  font-size: 16px;
  transition: transform 0.25s ease-in-out;
}
.questions-accordion li a.uk-accordion-title:hover {
  color: #829E8C;
  background-color: #f5f7f6;
}
.questions-accordion li .uk-accordion-content {
  padding: 30px;
  margin: 0;
}
.questions-accordion li.uk-open a.uk-accordion-title {
  background-color: #829E8C;
  color: #ffffff;
}
.questions-accordion li.uk-open a.uk-accordion-title i,
.questions-accordion li.uk-open a.uk-accordion-title svg {
  transform: rotate(90deg);
}

/* OFF CANVAS MENUS
-------------------------------------------------- */
.uk-offcanvas-overlay.uk-open::before {
  background-color: rgba(245, 247, 246, 0.9);
}
.uk-offcanvas-bar {
  width: 50vw;
  left: -50vw;
  box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16);
  padding: 0;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  overflow-y: scroll;
}
.uk-offcanvas-bar ul.side-menu > li:last-of-type > a {
  border-bottom: 0;
}
.uk-offcanvas-bar ul.side-menu:last-of-type {
  flex: 1 0 auto;
  box-shadow: 0px -10px 30px -15px rgba(0, 0, 0, 0.3);
}
.uk-offcanvas-bar ul.side-menu:last-of-type > li:first-of-type > a {
  border-top: 1px solid #e0e0e0;
}
.uk-offcanvas-bar-animation {
  transition: left 0.6s cubic-bezier(0.65, 0, 0.35, 1);
}

/* GLOBAL ALERT
-------------------------------------------------- */
#global-alert {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: #829E8C;
  z-index: 9999;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#global-alert button#global-alert-close {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  border: 0;
  background-color: transparent;
  color: #ffffff;
  font-size: 21px;
  cursor: pointer;
}
#global-alert button#global-alert-close:focus {
  outline: none;
}

/* NOTIFICATION
-------------------------------------------------- */
.uk-notification {
  box-sizing: content-box;
  z-index: 9999;
  color: #ffffff;
  width: unset;
  top: 0px;
  display: none;
}
.uk-notification-message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 75px;
  padding: 0em 2em;
  font-size: 18px;
  font-weight: 300;
}
.uk-notification-message div {
  display: flex;
  align-items: center;
  margin-right: 26px;
}
.uk-notification-message-success {
  background-color: #18bbb9;
  color: #ffffff;
}
.uk-notification-top-right {
  top: 75px;
  right: 0;
}
.uk-notification-close {
  color: #ffffff;
  top: auto;
  bottom: auto;
}

/* PROMO BANNER
-------------------------------------------------- */
.promo-banner {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 7px 10px;
  background-color: #829E8C;
  color: #ffffff;
  border-radius: 3px;
  font-family: "sofia-pro";
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  font-family: "sofia-pro", "Helvetica", "Arial", sans-serif;
}

/* PAGINATION
-------------------------------------------------- */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination > * {
  margin: 0px 2px;
}
.pagination__button {
  padding: 8px 20px;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  height: 40px;
  font-size: 14px;
  line-height: 21px;
  color: #222222;
}
.pagination__button i,
.pagination__button svg {
  font-size: 12px;
}
.pagination__number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  font-size: 14px;
  line-height: 21px;
  color: #222222;
}
.pagination__number--active {
  background-color: #829E8C;
  color: #ffffff;
  border: 1px solid #829E8C;
}
.pagination__button, .pagination__number {
  transition: 0.15s ease-in-out;
}
.pagination__button:hover, .pagination__number:hover {
  text-decoration: none;
  background-color: #829E8C;
  color: #ffffff;
  border: 1px solid #829E8C;
}

/* STATUSES
-------------------------------------------------- */
.status {
  font-size: 18px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.status i,
.status svg {
  flex: 0 0 18px;
  margin-right: 10px;
}
.status p {
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
}
.status--success {
  color: #1eaf3a;
}
.status--success p {
  color: #1eaf3a;
}
.status--warning {
  color: #ff6601;
}
.status--warning p {
  color: #ff6601;
}
.status--danger {
  color: #ff2424;
}
.status--danger p {
  color: #ff2424;
}

/* SCROLL TO TOP BUTTON
-------------------------------------------------- */
a#scroll-top {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  border-radius: 3px 0 0 0;
  border: 0;
  background-color: #829E8C;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
a#scroll-top.hidden {
  bottom: -50px;
  opacity: 0;
  transition: all 0.6s cubic-bezier(0.65, 0, 0.35, 1);
}
a#scroll-top:hover {
  background-color: #688572;
}

/* STEPS
-------------------------------------------------- */
.steps {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.steps .step {
  background-color: #f8f8f8;
  padding: 0px 30px 0px 50px;
  position: relative;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 3px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  text-decoration: none;
}
.steps .step:before, .steps .step:after {
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  border-color: transparent;
  border-width: 25px;
  margin-top: -25px;
}
.steps .step:after {
  left: 100%;
  border-left-color: #f8f8f8;
  z-index: 999;
}
.steps .step:before {
  left: 0;
  border-left-color: #ffffff;
}
.steps .step:first-of-type {
  padding: 0px 30px 0px 25px;
}
.steps .step:first-of-type:before {
  display: none;
}
.steps .step:last-of-type {
  padding: 0px 25px 0px 50px;
}
.steps .step:last-of-type:after {
  display: none;
}
.steps .step--current {
  background-color: #829E8C !important;
  color: #ffffff !important;
}
.steps .step--current:after {
  border-left-color: #829E8C !important;
}
.steps .step--next:hover {
  cursor: not-allowed;
  background-color: #e0e0e0;
}
.steps .step--next:hover:after {
  border-left-color: #e0e0e0;
}
.steps .step--previous {
  background-color: #f5f7f6;
  color: #829E8C;
}
.steps .step--previous:after {
  border-left-color: #f5f7f6;
}
.steps .step--previous:hover {
  background-color: #829E8C;
  color: #ffffff;
}
.steps .step--previous:hover:after {
  border-left-color: #829E8C;
}

/* TABS
-------------------------------------------------- */
.tabs-nav {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.tabs-nav li {
  margin: 0px 1px;
  padding: 0;
}
.tabs-nav li.uk-active a {
  background-color: #829E8C;
  color: #ffffff;
  font-weight: 600;
  padding: 10px 14px;
}
.tabs-nav li a {
  font-size: 14px;
  color: #829E8C;
  background-color: #f5f7f6;
  padding: 10px 15px;
  border-radius: 3px 3px 0 0;
  display: block;
  text-decoration: none;
  transition: 0.15s ease-in-out;
}
.tabs-nav li a:hover {
  background-color: #71917c;
  color: #ffffff;
}

/* CUSTOM DROPDOWN
-------------------------------------------------- */
.custom-dropdown {
  background-color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  border: 1px solid #e0e0e0;
  padding: 0;
}
.custom-dropdown ul > li {
  border-bottom: 1px solid #e0e0e0;
  padding: 0;
}
.custom-dropdown ul > li > a {
  padding: 12px 20px;
  font-size: 16px;
  color: #222222;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
}
.custom-dropdown ul > li > a:hover {
  background-color: #f5f7f6;
  color: #829E8C;
  text-decoration: none;
}
.custom-dropdown ul > li:last-of-type {
  border-bottom: 0;
}

/* TOGGLE SWITCH
-------------------------------------------------- */
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 22px;
}
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #e0e0e0;
  transition: 0.4s;
  border-radius: 9999px;
}
.switch .slider:before {
  position: absolute;
  content: " ";
  height: 22px;
  width: 22px;
  left: 0;
  bottom: 0;
  background-color: #ffffff;
  transition: 0.4s;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 9999px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch input:checked + .slider {
  background-color: #829E8C;
}
.switch input:checked + .slider:before {
  transform: translateX(24px);
}
.switch input:checked + .slider--disabled {
  background-color: #f5f5f5;
}
.switch input:focus + .slider {
  box-shadow: 0 0 1px #829E8C;
}
.switch.small {
  width: 32px;
  height: 20px;
}
.switch.small .slider:before {
  height: 16px;
  width: 16px;
  bottom: 2px;
  left: 2px;
  box-shadow: none;
}
.switch.small input:checked + .slider:before {
  transform: translateX(12px);
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
.uk-h1 {
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  color: #222222;
}

h2,
.uk-h2 {
  font-weight: bold;
  font-size: 32px;
  line-height: 38.4px;
  color: #222222;
}

h3,
.uk-h3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 28.8px;
  color: #222222;
}

h5,
.uk-h5 {
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #222222;
}

h6,
.uk-h6 {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}

p {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}
p strong,
p b {
  font-weight: 600;
}
p a {
  color: #829E8C;
  text-decoration: underline;
}
p a:hover {
  color: #688572;
  text-decoration: none;
}

/*=============================================
=                 MAIN LAYOUT                 =
=============================================*/
button#sidebar-toggle {
  z-index: 1000;
  position: fixed;
  left: 330px;
  top: 75px;
  width: 50px;
  height: 50px;
  border: 0;
  background-color: #829E8C;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out, left 0.6s cubic-bezier(0.65, 0, 0.35, 1);
}
button#sidebar-toggle i,
button#sidebar-toggle svg {
  transition: transform 0.6s cubic-bezier(0.65, 0, 0.35, 1);
}
button#sidebar-toggle:hover {
  background-color: #f5f7f6;
  color: #829E8C;
}
button#sidebar-toggle:focus {
  outline: none;
}
button#sidebar-toggle.hidden {
  left: 0;
}
button#sidebar-toggle.hidden i,
button#sidebar-toggle.hidden svg {
  transform: rotate(180deg);
}

nav#sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 330px;
  z-index: 1;
  border-right: 1px solid #e0e0e0;
  transition: left 0.6s cubic-bezier(0.65, 0, 0.35, 1);
  background-color: #ffffff;
  overflow-y: scroll;
  padding: 200px 0;
}
nav#sidebar a#logo-box {
  height: 200px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: #ffffff;
}
nav#sidebar a#logo-box .uk-svg {
  color: #222222;
  transition: color 0.15s ease-in-out;
}
nav#sidebar a#logo-box > img {
  max-height: 75px;
  width: auto;
}
nav#sidebar a#logo-box:hover .uk-svg {
  color: #829E8C;
}
nav#sidebar.hidden {
  left: -330px;
}
nav#sidebar > .bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 200px;
  background-color: #ffffff;
  border-top: 1px solid #e0e0e0;
  padding: 20px;
  display: flex;
  flex-flow: column;
  justify-content: center;
}
nav#sidebar > ul.side-menu {
  overflow-y: scroll;
  max-height: 100%;
}

.top-bar__item--border-left {
  border-left: 1px solid #e0e0e0;
}

nav#top-bar {
  height: 75px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #ffffff;
  z-index: 9999999;
}
nav#top-bar section#left {
  position: relative;
}
nav#top-bar section#left #top-bar-search {
  padding: 0px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 100%;
  border-right: 1px solid #e0e0e0;
}
nav#top-bar section#left #top-bar-search > input {
  width: 100%;
  background-image: url("/assets/images/icons/loupe@2x.png");
  background-size: 20px 20px;
  background-position: left center;
  background-repeat: no-repeat;
  border: 0;
  height: 25px;
  padding-left: 30px;
  font-family: "sofia-pro", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}
nav#top-bar section#left #top-bar-search > input::placeholder {
  color: #f5f5f5;
}
nav#top-bar section#left #top-bar-search > input:focus {
  outline: none;
}
nav#top-bar section#left .top-bar-search-results {
  position: absolute;
  width: 390px;
  background-color: #ffffff;
  z-index: 9999999;
  border: 1px solid #e0e0e0;
  border-left: 0;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-flow: column;
}
nav#top-bar section#left .top-bar-search-results a {
  border-bottom: 1px solid #e0e0e0;
}
nav#top-bar section#left .top-bar-search-results a.category {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 30px;
  color: #222222;
  transition: all 0.4s cubic-bezier(0.83, 0, 0.17, 1);
}
nav#top-bar section#left .top-bar-search-results a.category i,
nav#top-bar section#left .top-bar-search-results a.category svg {
  opacity: 0;
  transform: translateX(-15px);
  transition: all 0.4s cubic-bezier(0.83, 0, 0.17, 1);
  color: #829E8C;
}
nav#top-bar section#left .top-bar-search-results a.category:hover {
  background-color: #f5f7f6;
  color: #829E8C;
  text-decoration: none;
}
nav#top-bar section#left .top-bar-search-results a.category:hover i,
nav#top-bar section#left .top-bar-search-results a.category:hover svg {
  opacity: 1;
  transform: translateX(0);
}
nav#top-bar section#left .top-bar-search-results a.product {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 30px;
  transition: all 0.4s cubic-bezier(0.83, 0, 0.17, 1);
}
nav#top-bar section#left .top-bar-search-results a.product .image {
  flex: 0 0 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
}
nav#top-bar section#left .top-bar-search-results a.product .image img {
  width: 100%;
}
nav#top-bar section#left .top-bar-search-results a.product .content p {
  margin: 0;
  color: #222222;
  transition: all 0.4s cubic-bezier(0.83, 0, 0.17, 1);
}
nav#top-bar section#left .top-bar-search-results a.product:hover {
  background-color: #f5f7f6;
  text-decoration: none;
}
nav#top-bar section#left .top-bar-search-results a.product:hover .content p {
  color: #829E8C;
}
nav#top-bar section#left .top-bar-search-results a:last-of-type {
  border-bottom: 0;
}
nav#top-bar section#center {
  display: flex;
  justify-content: center;
}
nav#top-bar section#right {
  border-left: 1px solid #e0e0e0;
}
nav#top-bar section > ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  height: 100%;
}
nav#top-bar section > ul > li {
  height: 100%;
}
nav#top-bar section > ul > li:first-of-type {
  margin-left: 15px;
}
nav#top-bar section > ul > li:last-of-type {
  margin-right: 15px;
}
nav#top-bar section > ul > li > a {
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  margin: 0 15px;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}
nav#top-bar section > ul > li > a:hover {
  color: #829E8C;
  text-decoration: none;
}
nav#top-bar section > ul > li > a > .cart-badge {
  position: absolute;
  left: 12px;
  top: 40%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  border-radius: 9999px;
  background-color: #829E8C;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  color: #ffffff;
}

main#content {
  position: relative;
  padding-left: 330px;
  transition: padding-left 0.6s cubic-bezier(0.65, 0, 0.35, 1);
}
main#content.hidden {
  padding-left: 0;
}

.alt-nav {
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
}

ul.side-menu {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  flex-flow: column;
  overflow-y: scroll;
}
ul.side-menu li {
  width: 100%;
}
ul.side-menu li a {
  width: 100%;
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0px 30px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  text-decoration: none;
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
}
ul.side-menu li a i,
ul.side-menu li a svg {
  font-size: 12px;
}
ul.side-menu li a.category-back {
  color: #829E8C;
}
ul.side-menu li a.category-header {
  font-weight: 600;
}
ul.side-menu li a.category-item {
  padding-left: 60px;
}
ul.side-menu li a:hover {
  background-color: #f5f7f6;
  color: #829E8C;
  font-weight: 600;
}

.mobile-nav {
  z-index: 999;
  background-color: #ffffff;
}

.align-self-end {
  align-self: flex-end;
}

/* LOGIN
-------------------------------------------------- */
.login-wrapper {
  padding: 60px 0px;
  width: 100vw;
  min-height: 100vh;
  background-color: #f5f7f6;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.login-wrapper .login-logo {
  margin-bottom: 60px;
}
.login-wrapper .login-logo img,
.login-wrapper .login-logo svg,
.login-wrapper .login-logo .uk-svg {
  color: #222222;
}
.login-wrapper .login-box {
  width: 460px;
  background-color: #ffffff;
  padding: 60px;
  border-radius: 3px;
  box-shadow: 0 1.4px 2.2px -12px rgba(0, 0, 0, 0.02), 0 3.3px 5.3px -12px rgba(0, 0, 0, 0.028), 0 6.3px 10px -12px rgba(0, 0, 0, 0.035), 0 11.2px 17.9px -12px rgba(0, 0, 0, 0.042), 0 20.9px 33.4px -12px rgba(0, 0, 0, 0.05), 0 50px 80px -12px rgba(0, 0, 0, 0.07);
}
.login-wrapper .login-box--wide {
  width: 690px;
}

/* DASHBOARD
-------------------------------------------------- */
.dashboard-button {
  width: 100%;
  height: 100%;
  padding: 40px 60px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f5f7f6;
  color: #829E8C;
  font-weight: bold;
  font-size: 24px;
  line-height: 28.8px;
  border-radius: 3px;
}
.dashboard-button i,
.dashboard-button svg {
  font-size: 18px;
}
.dashboard-button:hover {
  text-decoration: none;
  background-color: #829E8C;
  color: #ffffff;
}

/* PRODUCTS
-------------------------------------------------- */
.products-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 18px;
}
.products-bar > section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.products-bar__sorting {
  width: 180px;
  height: 40px;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  background-color: transparent;
  padding-left: 20px;
  padding-right: 20px;
  font-family: "sofia-pro", sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #222222;
}
.products-bar__toggle {
  color: #829E8C;
}
.products-bar__toggle--active {
  color: #bbcbc1;
}
.products-bar__toggle:hover {
  color: #bbcbc1;
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 60px 30px;
  grid-template-areas: ". . .";
}
.products-grid--large {
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas: ". . . .";
}

.products-row-item {
  position: relative;
  border-bottom: 1px solid #e0e0e0;
  padding: 15px 0;
  min-height: 100px;
}
.products-row-item__sku {
  font-weight: bold;
  font-size: 13px;
  line-height: 21px;
  color: #829E8C;
}
.products-row-item__name {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}

.product {
  text-decoration: none;
  display: block;
}
.product__image {
  position: relative;
  transition: box-shadow 0.2s ease-in-out;
  border-radius: 3px;
  width: 100%;
  padding-top: 75%;
}
.product__image--cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.product__image--container > img {
  position: absolute;
  top: 40px;
  bottom: 40px;
  height: calc(100% - 80px);
  left: 50%;
  transform: translateX(-50%);
}
.product__image--halved > img {
  top: 25%;
  bottom: 25%;
  height: 50%;
}
.product__image--bordered {
  border: 1px solid #e0e0e0;
}
.product__sku {
  margin-top: 30px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #829E8C;
}
.product__title {
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #222222;
  margin-top: 0;
}
.product__inputs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.product__label {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #222222;
}
.product__select {
  width: 200px;
  height: 40px;
  background-color: transparent;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: "sofia-pro", sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #222222;
}
.product__input {
  width: 60px;
  height: 40px;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  text-align: center;
  font-family: "sofia-pro", sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #222222;
}
.product__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product__price {
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  color: #222222;
}
.product__discount {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #f5f5f5;
}
.product__discount > span {
  text-decoration: line-through;
}
.product__stock {
  margin-top: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.product__stock i,
.product__stock svg {
  font-size: 21px;
  margin-right: 10px;
  flex: 0 0 21px;
}
.product__stock p {
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
}
.product__stock--success i,
.product__stock--success svg,
.product__stock--success p {
  color: #1eaf3a;
}
.product__stock--warning i,
.product__stock--warning svg,
.product__stock--warning p {
  color: #ff6601;
}
.product__stock--danger i,
.product__stock--danger svg,
.product__stock--danger p {
  color: #ff2424;
}
.product:hover {
  text-decoration: none;
}
.product:hover .product__image {
  box-shadow: 0px 5px 15px rgba(46, 44, 44, 0.08);
}

.products-column {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  gap: 60px 30px;
  grid-template-areas: ". .";
}
.products-column--large {
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: ". . .";
}
.products-column .product__image--container > img {
  position: absolute;
  top: 20px;
  bottom: 20px;
  height: calc(100% - 40px);
  left: 50%;
  transform: translateX(-50%);
}
.products-column .product__image--halved > img {
  top: 25%;
  bottom: 25%;
  height: 50%;
}
.products-column .product__footer {
  flex-flow: column;
  align-items: flex-start;
}

/* PRODUCT DETAIL
-------------------------------------------------- */
.product-gallery__image {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-gallery__nav {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px 10px;
  margin: 40px 0 0 0;
  padding: 0;
  list-style-type: none;
}
.product-gallery__nav > li > a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border: 1px solid #e0e0e0;
  width: 100%;
  height: 100%;
}
.product-gallery__nav > li > a img {
  max-width: 100%;
  max-height: 100%;
}
.product-gallery__nav > li > a:hover {
  border: 1px solid #829E8C;
}
.product-gallery__nav > li > a.product-gallery__open {
  background-color: #829E8C;
  color: #ffffff;
  border: 1px solid #829E8C;
  text-decoration: none;
  font-size: 21px;
}
.product-gallery__nav > li > a.product-gallery__open:hover {
  background-color: #71917c;
  border: 1px solid #71917c;
}
.product-gallery__nav > li.uk-active a {
  box-shadow: 0px 0px 0px 1px #829E8C;
  border: 1px solid #829E8C;
}

/* CART
-------------------------------------------------- */
.cart__row {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  gap: 0 10px;
  grid-template-areas: "delete banner image info info info info info info input input price";
}
.cart__row .delete {
  grid-area: delete;
}
.cart__row .banner {
  grid-area: banner;
}
.cart__row .image {
  grid-area: image;
}
.cart__row .info {
  grid-area: info;
}
.cart__row .input {
  grid-area: input;
}
.cart__row .price {
  grid-area: price;
}

/* CART
-------------------------------------------------- */
.cart__footer {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 8px 25px;
  grid-template-areas: ". .";
  margin-top: 30px;
}
.cart__footer__delivery {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, 1fr);
  gap: 8px 25px;
  grid-template-areas: "title title" "form form";
  align-items: end;
}
.cart__footer__delivery--title {
  grid-area: title;
}
.cart__footer__delivery .form-custom {
  grid-area: form;
}
.cart__footer__totals {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: repeat(4, 1fr);
  gap: 8px 25px;
  grid-template-areas: ". ." ". ." ". ." ". .";
  align-items: end;
}
.cart__footer__totals > div {
  text-align: right;
}

.cart-overview__footer {
  display: grid;
  grid-template-columns: 4fr 2fr;
  grid-template-rows: repeat(4, 1fr);
  gap: 8px 25px;
  grid-template-areas: ". ." ". ." ". ." ". .";
  margin-top: 30px;
  align-items: end;
}
.cart-overview__footer > div {
  text-align: right;
}

/* PAGES
-------------------------------------------------- */
.repeater-block li .repeater-block__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f7f6;
  color: #829E8C;
  padding: 16px 20px;
}
.repeater-block li .repeater-block__title--small {
  padding: 6px 20px;
  font-size: medium;
}
.repeater-block li .repeater-block__title .handler {
  margin-top: -6px;
}
.repeater-block li .repeater-block__title .block-name {
  font-weight: 300;
  font-size: 18px;
  color: #829E8C;
}
.repeater-block li .repeater-block__title .block-name > strong {
  font-weight: 900;
}
.repeater-block li .repeater-block__title .indicator {
  font-size: 12px;
}
.repeater-block li .repeater-block__title > div > button {
  background-color: transparent;
  color: #829E8C;
  border: 0;
  cursor: pointer;
}
.repeater-block li .uk-accordion-content {
  padding: 20px;
  margin: 0;
}
.repeater-block li.uk-open .uk-accordion-title {
  background-color: #829E8C;
  color: #ffffff;
}
.repeater-block li.uk-open .uk-accordion-title * {
  color: #ffffff !important;
}
.repeater-block li.uk-open .uk-accordion-title .indicator {
  transform: rotate(90deg);
}
.repeater-block li.uk-open .uk-accordion-title .switch .slider {
  background-color: #ffffff;
}
.repeater-block li.uk-open .uk-accordion-title .switch .slider:before {
  background-color: #829E8C;
}
.repeater-block li.uk-open .uk-accordion-title .switch input:checked + .slider {
  background-color: #ffffff;
}

.page-accordion li .uk-accordion-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f7f6;
  color: #829E8C;
  padding: 16px 20px;
}
.page-accordion li .uk-accordion-title--small {
  padding: 6px 20px;
  font-size: medium;
}
.page-accordion li .uk-accordion-title .handler {
  margin-top: -6px;
}
.page-accordion li .uk-accordion-title .block-name {
  font-weight: 300;
  font-size: 18px;
  color: #829E8C;
}
.page-accordion li .uk-accordion-title .block-name > strong {
  font-weight: 900;
}
.page-accordion li .uk-accordion-title .indicator {
  font-size: 12px;
}
.page-accordion li .uk-accordion-title > div > button {
  background-color: transparent;
  color: #829E8C;
  border: 0;
  cursor: pointer;
}
.page-accordion li .uk-accordion-content {
  padding: 20px;
  margin: 0;
}
.page-accordion li.uk-open .uk-accordion-title {
  background-color: #829E8C;
  color: #ffffff;
}
.page-accordion li.uk-open .uk-accordion-title * {
  color: #ffffff !important;
}
.page-accordion li.uk-open .uk-accordion-title .indicator {
  transform: rotate(90deg);
}
.page-accordion li.uk-open .uk-accordion-title .switch .slider {
  background-color: #ffffff;
}
.page-accordion li.uk-open .uk-accordion-title .switch .slider:before {
  background-color: #829E8C;
}
.page-accordion li.uk-open .uk-accordion-title .switch input:checked + .slider {
  background-color: #ffffff;
}

.search-dropdown {
  position: absolute;
  background-color: #ffffff;
  z-index: 9999999;
  border: 1px solid #e0e0e0;
  border-left: 0;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-flow: column;
  top: 80px;
}
.search-dropdown a {
  border-bottom: 1px solid #e0e0e0;
}
.search-dropdown a.category {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 30px;
  color: #222222;
  transition: all 0.4s cubic-bezier(0.83, 0, 0.17, 1);
}
.search-dropdown a.category i,
.search-dropdown a.category svg {
  opacity: 0;
  transform: translateX(-15px);
  transition: all 0.4s cubic-bezier(0.83, 0, 0.17, 1);
  color: #829E8C;
}
.search-dropdown a.category:hover {
  background-color: #f5f7f6;
  color: #829E8C;
  text-decoration: none;
}
.search-dropdown a.category:hover i,
.search-dropdown a.category:hover svg {
  opacity: 1;
  transform: translateX(0);
}
.search-dropdown a.product {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 30px;
  transition: all 0.4s cubic-bezier(0.83, 0, 0.17, 1);
}
.search-dropdown a.product .image {
  flex: 0 0 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
}
.search-dropdown a.product .image img {
  width: 100%;
}
.search-dropdown a.product .content p {
  margin: 0;
  color: #222222;
  transition: all 0.4s cubic-bezier(0.83, 0, 0.17, 1);
}
.search-dropdown a.product:hover {
  background-color: #f5f7f6;
  text-decoration: none;
}
.search-dropdown a.product:hover .content p {
  color: #829E8C;
}
.search-dropdown a:last-of-type {
  border-bottom: 0;
}

/* CUSTOMER DATA
-------------------------------------------------- */
.delivery__address--text {
  background: #f5f7f6;
  border-radius: 3px;
  padding: 2.5em;
  cursor: pointer;
}
.delivery__address--add {
  height: 100%;
  width: 100%;
}
.delivery__address.selected p {
  color: #fff;
}
.delivery__address.selected .delivery__address--text {
  background: #829E8C;
  box-shadow: 0px 5px 15px rgba(46, 44, 44, 0.08);
}

.favorite-button:hover {
  color: #bbcbc1;
}

@media (max-width: 1600px) {
  button#sidebar-toggle {
    left: 270px;
  }

  nav#sidebar {
    width: 270px;
  }
  nav#sidebar.hidden {
    left: -270px;
  }

  nav#top-bar section#left #top-bar-search {
    padding: 0px 20px;
    width: 280px;
  }
  nav#top-bar section > ul > li:first-of-type {
    margin-left: 5px;
  }
  nav#top-bar section > ul > li:last-of-type {
    margin-right: 5px;
  }

  main#content {
    padding-left: 270px;
  }
}
@media (max-width: 1300px) {
  button#sidebar-toggle {
    left: 250px;
  }

  nav#sidebar {
    width: 250px;
  }
  nav#sidebar a#logo-box {
    height: 150px;
  }
  nav#sidebar ul {
    max-height: calc(100vh - 150px);
  }
  nav#sidebar ul li a {
    min-height: 50px;
    height: auto;
    padding: 10px 25px;
  }
  nav#sidebar ul li a.category-item {
    padding-left: 50px;
  }
  nav#sidebar.hidden {
    left: -250px;
  }

  nav#top-bar section#left #top-bar-search {
    padding: 0px 10px 0 25px;
    width: 250px;
  }
  nav#top-bar section > ul > li:first-of-type {
    margin-left: 5px;
  }
  nav#top-bar section > ul > li:last-of-type {
    margin-right: 5px;
  }
  nav#top-bar section > ul > li > a {
    margin: 0 10px;
    font-size: 14px;
  }

  main#content {
    padding-left: 250px;
  }
}
@media (max-width: 1200px) {
  .uk-notification-top-right {
    top: 80px;
  }

  input.custom-number-input {
    padding-left: 0;
  }

  main#content {
    padding-left: 0;
  }
}
@media (max-width: 960px) {
  .products-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: ". .";
  }

  .cart__footer {
    display: grid;
    grid-template-columns: 4fr 2fr;
  }
  .cart__footer__delivery {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "title" "form";
  }
  .cart__footer__totals {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(8, 1fr);
    grid-template-areas: "." "." "." "." "." "." "." ".";
  }
  .cart .column-reverse-md {
    flex-direction: column-reverse;
  }

  .steps .step {
    padding: 0px 20px 0px 40px;
    margin-right: 10px;
  }
  .steps .step:first-of-type {
    padding: 0px 20px 0px 25px;
  }
  .steps .step:last-of-type {
    padding: 0px 25px 0px 40px;
  }
}
@media (max-width: 640px) {
  .uk-offcanvas-bar {
    width: 100vw;
    left: -100vw;
  }

  .login-wrapper {
    width: 100vw;
    height: 100vh;
    padding: 0px 30px;
  }
  .login-wrapper .login-logo {
    margin-bottom: 30px;
  }
  .login-wrapper .login-box {
    width: 100%;
    padding: 25px;
  }

  .products-grid {
    grid-template-columns: 1fr;
    gap: 40px 30px;
    grid-template-areas: ".";
  }

  .products-bar {
    width: 100%;
    flex-flow: column;
  }
  .products-bar > section {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .products-bar__sorting {
    width: 180px;
    height: 40px;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    background-color: transparent;
    padding-left: 20px;
    padding-right: 20px;
    font-family: "sofia-pro", sans-serif;
    font-size: 14px;
    line-height: 21px;
    color: #222222;
  }
  .products-bar__toggle {
    color: #829E8C;
  }
  .products-bar__toggle:hover {
    color: #222222;
  }

  .products-column {
    grid-template-columns: 1fr;
    gap: 40px 30px;
    grid-template-areas: ".";
  }

  .product-gallery__nav {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  .cart__footer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    gap: 0px 0px;
    grid-template-areas: "." ".";
    margin-top: 80px;
  }
  .cart__footer__delivery {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    gap: 0px 0px;
    grid-template-areas: "title" "form";
    align-items: end;
    margin-bottom: 30px;
  }
  .cart__footer__delivery--title {
    margin-bottom: 1.5em;
  }
  .cart__footer__totals {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(8, 1fr);
    gap: 0px 0px;
    grid-template-areas: "." "." "." "." "." "." "." ".";
    align-items: end;
  }
  .cart__footer__totals > div {
    text-align: left;
  }
  .cart__footer__totals > div:nth-child(even) {
    margin-bottom: 10px;
  }

  .steps {
    flex-flow: column;
  }
  .steps .step {
    width: 100%;
    margin-right: 0;
    border-bottom: 1px solid #e0e0e0;
    border-radius: 0;
  }
  .steps .step:before, .steps .step:after {
    display: none;
  }
  .steps .step:first-of-type {
    padding: 0;
    border-radius: 3px 3px 0 0;
  }
  .steps .step:last-of-type {
    padding: 0;
    border-bottom: 0;
    border-radius: 0 0 3px 3px;
  }

  .uk-notification-top-right {
    top: 80px;
  }
  .uk-notification-message {
    padding: 0.5em 2em;
  }
}
/* INTERNET EXPLORER
-------------------------------------------------- */
@media all and (-ms-high-contrast: none) {
  .products-grid {
    display: -ms-grid;
    -ms-grid-columns: repeat(3, 1fr);
    -ms-grid-rows: 1fr;
  }
  .products-grid--large {
    -ms-grid-columns: repeat(4, 1fr);
  }

  .products-column {
    display: -ms-grid;
    -ms-grid-columns: repeat(2, 1fr);
    -ms-grid-rows: 1fr;
  }
  .products-column--large {
    -ms-grid-columns: repeat(3, 1fr);
  }

  .product-gallery__nav {
    display: -ms-grid;
    -ms-grid-columns: repeat(4, 1fr);
    -ms-grid-rows: repeat(2, 1fr);
  }

  .cart__footer {
    display: -ms-grid;
    -ms-grid-columns: 5fr 1fr;
    -ms-grid-rows: repeat(4, 1fr);
  }
}