// ==========[ GENERAL ]==========

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
.uk-h1 {
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  color: $black;
}

h2,
.uk-h2 {
  font-weight: bold;
  font-size: 32px;
  line-height: 38.4px;
  color: $black;
}

h3,
.uk-h3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 28.8px;
  color: $black;
}

h5,
.uk-h5 {
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: $black;
}

h6,
.uk-h6 {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: $black;
}

p {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: $black;

  strong,
  b {
    font-weight: 600;
  }

  a {
    color: $primary;
    text-decoration: underline;

    &:hover {
      color: darken($color: $primary, $amount: 10%);
      text-decoration: none;
    }
  }
}

/*=============================================
=                 MAIN LAYOUT                 =
=============================================*/

button#sidebar-toggle {
  z-index: 1000;
  position: fixed;
  left: 330px;
  top: 75px;
  width: 50px;
  height: 50px;
  border: 0;
  background-color: $primary;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out, left 0.6s cubic-bezier(0.65, 0, 0.35, 1);

  i,
  svg {
    transition: transform 0.6s cubic-bezier(0.65, 0, 0.35, 1);
  }

  &:hover {
    background-color: $secondary;
    color: $primary;
  }

  &:focus {
    outline: none;
  }

  &.hidden {
    left: 0;

    i,
    svg {
      transform: rotate(180deg);
    }
  }
}

nav#sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 330px;
  z-index: 1;
  border-right: 1px solid $lines;
  transition: left 0.6s cubic-bezier(0.65, 0, 0.35, 1);
  background-color: $white;
  overflow-y: scroll;
  padding: 200px 0;

  a#logo-box {
    height: 200px;
    border-bottom: 1px solid $lines;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background-color: $white;

    .uk-svg {
      color: $black;
      transition: color 0.15s ease-in-out;
    }

    > img {
      max-height: 75px;
      width: auto;
    }

    &:hover {
      .uk-svg {
        color: $primary;
      }
    }
  }

  &.hidden {
    left: -330px;
  }

  > .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 200px;
    background-color: $white;
    border-top: 1px solid $lines;
    padding: 20px;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }

  > ul.side-menu {
    overflow-y: scroll;
    max-height: 100%;
  }
}

.top-bar {
  &__item {
    &--border-left {
      border-left: 1px solid #e0e0e0;
    }
  }
}

nav#top-bar {
  height: 75px;
  border-bottom: 1px solid $lines;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: $white;
  z-index: 9999999;

  section {
    &#left {
      position: relative;

      #top-bar-search {
        padding: 0px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 320px;
        height: 100%;
        border-right: 1px solid $lines;

        > input {
          width: 100%;
          background-image: url('/assets/images/icons/loupe@2x.png');
          background-size: 20px 20px;
          background-position: left center;
          background-repeat: no-repeat;
          border: 0;
          height: 25px;
          padding-left: 30px;

          font-family: 'sofia-pro', sans-serif;
          font-size: 16px;
          line-height: 24px;
          color: $black;

          &::placeholder {
            color: $muted;
          }

          &:focus {
            outline: none;
          }
        }
      }

      .top-bar-search-results {
        position: absolute;
        width: 390px;
        background-color: $white;
        z-index: 9999999;
        border: 1px solid $lines;
        border-left: 0;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
        display: flex;
        flex-flow: column;

        a {
          border-bottom: 1px solid $lines;

          &.category {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 20px 30px;
            color: $black;
            transition: all 0.4s cubic-bezier(0.83, 0, 0.17, 1);

            i,
            svg {
              opacity: 0;
              transform: translateX(-15px);
              transition: all 0.4s cubic-bezier(0.83, 0, 0.17, 1);
              color: $primary;
            }

            &:hover {
              background-color: $secondary;
              color: $primary;
              text-decoration: none;

              i,
              svg {
                opacity: 1;
                transform: translateX(0);
              }
            }
          }

          &.product {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 20px 30px;
            transition: all 0.4s cubic-bezier(0.83, 0, 0.17, 1);

            .image {
              flex: 0 0 20%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 25px;

              img {
                width: 100%;
              }
            }

            .content {
              p {
                margin: 0;
                color: $black;
                transition: all 0.4s cubic-bezier(0.83, 0, 0.17, 1);
              }
            }

            &:hover {
              background-color: $secondary;
              text-decoration: none;

              .content p {
                color: $primary;
              }
            }
          }

          &:last-of-type {
            border-bottom: 0;
          }
        }
      }
    }

    &#center {
      display: flex;
      justify-content: center;
    }

    &#right {
      border-left: 1px solid $lines;
    }

    > ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: flex;
      justify-content: center;
      height: 100%;

      > li {
        height: 100%;

        &:first-of-type {
          margin-left: 15px;
        }

        &:last-of-type {
          margin-right: 15px;
        }

        > a {
          position: relative;
          display: flex;
          height: 100%;
          align-items: center;
          margin: 0 15px;

          font-size: 16px;
          line-height: 24px;
          color: $black;

          &:hover {
            color: $primary;
            text-decoration: none;
          }

          > .cart-badge {
            position: absolute;
            left: 12px;
            top: 40%;
            transform: translateY(-50%);

            width: 18px;
            height: 18px;
            border-radius: 9999px;
            background-color: $primary;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 2px;

            font-weight: bold;
            font-size: 12px;
            text-align: center;
            color: $white;
          }
        }
      }
    }
  }
}

main#content {
  position: relative;
  padding-left: 330px;
  transition: padding-left 0.6s cubic-bezier(0.65, 0, 0.35, 1);

  &.hidden {
    padding-left: 0;
  }
}

.alt-nav {
  background-color: $white;
  border-bottom: 1px solid $lines;
}

ul.side-menu {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  flex-flow: column;
  overflow-y: scroll;

  li {
    width: 100%;

    a {
      width: 100%;
      height: 50px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 0px 30px;
      border-bottom: 1px solid $lines;

      font-size: 16px;
      line-height: 24px;
      color: $black;
      text-decoration: none;

      transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;

      i,
      svg {
        font-size: 12px;
      }

      &.category-back {
        color: $primary;
      }

      &.category-header {
        font-weight: 600;
      }

      &.category-item {
        padding-left: 60px;
      }

      &:hover {
        background-color: $secondary;
        color: $primary;
        font-weight: 600;
      }
    }
  }
}

.mobile-nav {
  z-index: 999;
  background-color: $white;
}

.align-self-end {
  align-self: flex-end;
}

/* LOGIN
-------------------------------------------------- */

.login-wrapper {
  padding: 60px 0px;
  width: 100vw;
  min-height: 100vh;
  background-color: $secondary;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  .login-logo {
    margin-bottom: 60px;

    img,
    svg,
    .uk-svg {
      color: $black;
    }
  }

  .login-box {
    width: 460px;
    background-color: $white;
    padding: 60px;
    border-radius: 3px;
    box-shadow: 0 1.4px 2.2px -12px rgba(0, 0, 0, 0.02),
      0 3.3px 5.3px -12px rgba(0, 0, 0, 0.028),
      0 6.3px 10px -12px rgba(0, 0, 0, 0.035),
      0 11.2px 17.9px -12px rgba(0, 0, 0, 0.042),
      0 20.9px 33.4px -12px rgba(0, 0, 0, 0.05),
      0 50px 80px -12px rgba(0, 0, 0, 0.07);

    &--wide {
      width: 690px;
    }
  }
}

/* DASHBOARD
-------------------------------------------------- */

.dashboard-button {
  width: 100%;
  height: 100%;
  padding: 40px 60px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: $secondary;
  color: $primary;
  font-weight: bold;
  font-size: 24px;
  line-height: 28.8px;
  border-radius: 3px;

  i,
  svg {
    font-size: 18px;
  }

  &:hover {
    text-decoration: none;
    background-color: $primary;
    color: $white;
  }
}

/* PRODUCTS
-------------------------------------------------- */

.products-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid $lines;
  padding-bottom: 18px;

  > section {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__sorting {
    width: 180px;
    height: 40px;
    border: 1px solid $lines;
    border-radius: 3px;
    background-color: transparent;
    padding-left: 20px;
    padding-right: 20px;

    font-family: 'sofia-pro', sans-serif;
    font-size: 14px;
    line-height: 21px;
    color: $black;
  }

  &__toggle {
    color: $primary;

    &--active {
      color: lighten($primary, 20);
    }

    &:hover {
      color: lighten($primary, 20)
    }
  }
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 60px 30px;
  grid-template-areas: '. . .';

  &--large {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: '. . . .';
  }
}

.products-row-item {
  position: relative;
  border-bottom: 1px solid $lines;
  padding: 15px 0;
  min-height: 100px;

  &__sku {
    font-weight: bold;
    font-size: 13px;
    line-height: 21px;
    color: $primary;
  }

  &__name {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: $black;
  }
}

.product {
  text-decoration: none;
  display: block;

  &__image {
    position: relative;
    transition: box-shadow 0.2s ease-in-out;
    border-radius: 3px;
    width: 100%;
    padding-top: 75%;

    &--cover {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }

    &--container {
      > img {
        position: absolute;
        top: 40px;
        bottom: 40px;
        height: calc(100% - 80px);
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &--halved {
      > img {
        top: 25%;
        bottom: 25%;
        height: 50%;
      }
    }

    &--bordered {
      border: 1px solid $lines;
    }
  }

  &__sku {
    margin-top: 30px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: $primary;
  }

  &__title {
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    color: $black;
    margin-top: 0;
  }

  &__inputs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__label {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: $black;
  }

  &__select {
    width: 200px;
    height: 40px;
    background-color: transparent;
    border: 1px solid $lines;
    border-radius: 3px;
    padding-left: 15px;
    padding-right: 15px;

    font-family: 'sofia-pro', sans-serif;
    font-size: 14px;
    line-height: 21px;
    color: $black;
  }

  &__input {
    width: 60px;
    height: 40px;
    border: 1px solid $lines;
    border-radius: 3px;
    text-align: center;

    font-family: 'sofia-pro', sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: $black;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__price {
    font-weight: bold;
    font-size: 24px;
    line-height: 27px;
    color: $black;
  }

  &__discount {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: $muted;

    > span {
      text-decoration: line-through;
    }
  }

  &__stock {
    margin-top: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    i,
    svg {
      font-size: 21px;
      margin-right: 10px;
      flex: 0 0 21px;
    }

    p {
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
    }

    &--success {
      i,
      svg,
      p {
        color: $success-color;
      }
    }

    &--warning {
      i,
      svg,
      p {
        color: $warning-color;
      }
    }

    &--danger {
      i,
      svg,
      p {
        color: $danger-color;
      }
    }
  }

  &:hover {
    text-decoration: none;

    .product__image {
      box-shadow: 0px 5px 15px rgba(46, 44, 44, 0.08);
    }
  }
}

.products-column {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  gap: 60px 30px;
  grid-template-areas: '. .';

  &--large {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: '. . .';
  }

  .product {
    &__image {
      &--container {
        > img {
          position: absolute;
          top: 20px;
          bottom: 20px;
          height: calc(100% - 40px);
          left: 50%;
          transform: translateX(-50%);
        }
      }

      &--halved {
        > img {
          top: 25%;
          bottom: 25%;
          height: 50%;
        }
      }
    }

    &__footer {
      flex-flow: column;
      align-items: flex-start;
    }
  }
}

/* PRODUCT DETAIL
-------------------------------------------------- */

.product-gallery {
  &__image {
    background-color: $white;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__nav {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px 10px;

    margin: 40px 0 0 0;
    padding: 0;
    list-style-type: none;

    > li {
      > a {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        border: 1px solid $lines;
        width: 100%;
        height: 100%;

        img {
          max-width: 100%;
          max-height: 100%;
        }

        &:hover {
          border: 1px solid $primary;
        }

        &.product-gallery__open {
          background-color: $primary;
          color: $white;
          border: 1px solid $primary;
          text-decoration: none;
          font-size: 21px;

          &:hover {
            background-color: darken($color: $primary, $amount: 6%);
            border: 1px solid darken($color: $primary, $amount: 6%);
          }
        }
      }

      &.uk-active {
        a {
          box-shadow: 0px 0px 0px 1px $primary;
          border: 1px solid $primary;
        }
      }
    }
  }
}

/* CART
-------------------------------------------------- */

.cart {
  &__row {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr;
    gap: 0 10px;
    grid-template-areas: 'delete banner image info info info info info info input input price';

    .delete {
      grid-area: delete;
    }

    .banner {
      grid-area: banner;
    }

    .image {
      grid-area: image;
    }

    .info {
      grid-area: info;
    }

    .input {
      grid-area: input;
    }

    .price {
      grid-area: price;
    }
  }
}

/* CART
-------------------------------------------------- */

.cart {
  &__footer {
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 8px 25px;
    grid-template-areas: '. .';

    margin-top: 30px;

    &__delivery {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(2, 1fr);
      gap: 8px 25px;
      grid-template-areas: 'title title' 'form form';
      align-items: end;

      &--title{
        grid-area: title;
      }
      .form-custom {
        grid-area: form;
      }

    }
    &__totals {
      display: grid;
      grid-template-columns: 3fr 2fr;
      grid-template-rows: repeat(4, 1fr);
      gap: 8px 25px;
      grid-template-areas: '. .' '. .' '. .' '. .';
      align-items: end;

      > div {
        text-align: right;
      }
    }
  }
}

.cart-overview {
  &__footer {
    display: grid;
    grid-template-columns: 4fr 2fr;
    grid-template-rows: repeat(4, 1fr);
    gap: 8px 25px;
    grid-template-areas: '. .' '. .' '. .' '. .';

    margin-top: 30px;
    align-items: end;

    > div {
      text-align: right;
    }
  }
}

/* PAGES
-------------------------------------------------- */

.repeater-block {
  li {
    .repeater-block__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $secondary;
      color: $primary;
      padding: 16px 20px;

      &--small {
        padding: 6px 20px;
        font-size: medium;
      }

      .handler {
        margin-top: -6px;
      }

      .block-name {
        font-weight: 300;
        font-size: 18px;
        color: $primary;

        > strong {
          font-weight: 900;
        }
      }

      .indicator {
        font-size: 12px;
      }

      > div > button {
        background-color: transparent;
        color: $primary;
        border: 0;
        cursor: pointer;
      }
    }

    .uk-accordion-content {
      padding: 20px;
      margin: 0;
    }

    &.uk-open {
      .uk-accordion-title {
        background-color: $primary;
        color: $white;

        * {
          color: $white !important;
        }

        .indicator {
          transform: rotate(90deg);
        }

        .switch {
          .slider {
            background-color: $white;

            &:before {
              background-color: $primary;
            }
          }

          input {
            &:checked {
              + .slider {
                background-color: $white;
              }
            }
          }
        }
      }
    }
  }
}

.page-accordion {
  li {
    .uk-accordion-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $secondary;
      color: $primary;
      padding: 16px 20px;

      &--small {
        padding: 6px 20px;
        font-size: medium;
      }

      .handler {
        margin-top: -6px;
      }

      .block-name {
        font-weight: 300;
        font-size: 18px;
        color: $primary;

        > strong {
          font-weight: 900;
        }
      }

      .indicator {
        font-size: 12px;
      }

      > div > button {
        background-color: transparent;
        color: $primary;
        border: 0;
        cursor: pointer;
      }
    }

    .uk-accordion-content {
      padding: 20px;
      margin: 0;
    }

    &.uk-open {
      .uk-accordion-title {
        background-color: $primary;
        color: $white;

        * {
          color: $white !important;
        }

        .indicator {
          transform: rotate(90deg);
        }

        .switch {
          .slider {
            background-color: $white;

            &:before {
              background-color: $primary;
            }
          }

          input {
            &:checked {
              + .slider {
                background-color: $white;
              }
            }
          }
        }
      }
    }
  }
}

.search-dropdown {
  position: absolute;
  background-color: $white;
  z-index: 9999999;
  border: 1px solid $lines;
  border-left: 0;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-flow: column;
  top: 80px;

  a {
    border-bottom: 1px solid $lines;

    &.category {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 20px 30px;
      color: $black;
      transition: all 0.4s cubic-bezier(0.83, 0, 0.17, 1);

      i,
      svg {
        opacity: 0;
        transform: translateX(-15px);
        transition: all 0.4s cubic-bezier(0.83, 0, 0.17, 1);
        color: $primary;
      }

      &:hover {
        background-color: $secondary;
        color: $primary;
        text-decoration: none;

        i,
        svg {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }

    &.product {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 20px 30px;
      transition: all 0.4s cubic-bezier(0.83, 0, 0.17, 1);

      .image {
        flex: 0 0 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 25px;

        img {
          width: 100%;
        }
      }

      .content {
        p {
          margin: 0;
          color: $black;
          transition: all 0.4s cubic-bezier(0.83, 0, 0.17, 1);
        }
      }

      &:hover {
        background-color: $secondary;
        text-decoration: none;

        .content p {
          color: $primary;
        }
      }
    }

    &:last-of-type {
      border-bottom: 0;
    }
  }
}

/* CUSTOMER DATA
-------------------------------------------------- */

.delivery__address {

  &--text {
    background: $secondary;
    border-radius: 3px;
    padding: 2.5em;
    cursor: pointer;
  }

  &--add {
    height: 100%;
    width: 100%;
  }

  &.selected {

    p {
      color: #fff;
    }
    .delivery__address--text {
      background: $primary;
      box-shadow: 0px 5px 15px rgba(46, 44, 44, 0.08);
    }
  }
}

.favorite-button {
  &:hover {
    color: lighten($primary, 20);
  }
}