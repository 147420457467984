// ==========[ FONTS ]==========

// ==========[ COLORS ]==========

$primary: #829E8C;
$secondary: lighten(
  $color: $primary,
  $amount: 40%
);
$background: #f8f8f8;
$lines: #e0e0e0;
$muted: #f5f5f5;

$white: #ffffff;
$grey-light: #f8f8f8;
$grey: #e5e5e5;
$grey-dark: #666666;
$black: #222222;

$danger-color: #ff2424;
$success-color: #1eaf3a;
$warning-color: #ff6601;

$cookie-bar-background: $grey;
$cookie-body-background: $white;