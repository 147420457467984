/* UTILITIES
-------------------------------------------------- */

.sku {
  font-weight: bold;
  font-size: 13px;
  line-height: 21px;
  color: $primary;
  text-transform: uppercase;
}

.noselect {
  user-select: none;
}

.text {
  &--small {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
  }

  &--white {
    color: $white !important;
  }

  &--muted {
    color: $muted !important;
  }
}

.border-bottom {
  border-bottom: 1px solid $lines;
}

/* FORMS
-------------------------------------------------- */

form.form-custom {
  .form-custom__label {
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    color: $black;
  }

  .uk-input,
  .uk-select,
  .uk-textarea {
    height: 50px !important;
    background-color: transparent;
    border: 1px solid $lines;
    border-radius: 3px;
    padding: 0px 20px;

    font-family: 'sofia-pro', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: $black;

    &::placeholder {
      color: $muted;
    }

    &:focus {
      border: 1px solid $primary;
      color: $primary;
      font-weight: 600;
      box-shadow: 0px 0px 0px 1px $primary;

      &::placeholder {
        font-weight: 600;
      }
    }

    &:disabled {
      background-color: $muted;
      cursor: not-allowed;
    }

    &.uk-form-danger {
      border: 1px solid $danger-color;
      font-weight: bold;
      color: $danger-color;
      box-shadow: 0px 0px 0px 1px $danger-color;

      &::placeholder {
        color: $danger-color;
        font-weight: bold;
      }
    }
  }

  .uk-textarea {
    height: auto !important;
    padding: 20px;
  }

  .date-input {
    background-image: url('/assets/images/icons/calendar.svg');
    background-repeat: no-repeat;
    background-size: 22px;
    background-position: right 20px center;
  }

  .uk-checkbox {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    border: 1px solid $lines;
    background-color: transparent;
    margin-right: 10px;

    &:checked {
      background-color: $primary;
      border: 1px solid $primary;
    }
  }

  .uk-radio {
    width: 20px;
    height: 20px;
    border: 1px solid $lines;
    background-color: transparent;
    margin-right: 10px;

    &:checked {
      background-color: $primary;
      border: 1px solid $primary;
      background-size: 30px;
    }
  }

  .form-uploader {
    border: 1px dashed $lines;
    background-color: transparent;
    min-height: 100px;

    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: $muted;

    .uk-link {
      font-weight: 300;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: darken($color: $primary, $amount: 6%);
        text-decoration: none;
      }
    }

    &:hover {
      background-color: transparentize($color: $secondary, $amount: 0.5);
      color: $primary;
      border: 1px dashed $primary;
    }
  }

  .search-input {
    background-image: url('/assets/images/icons/loupe.svg');
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: right 20px center;
    padding-right: 45px;
  }
}

select.custom-select {
  width: 150px;
  height: 40px;
  background-color: transparent;
  border: 1px solid $lines;
  border-radius: 3px;
  padding: 0px 10px;

  font-family: 'sofia-pro', sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: $black;

  &:focus {
    border: 1px solid $primary;
    outline: none;
  }
}

input.custom-number-input {
  width: 60px;
  height: 50px;
  border: 1px solid $lines;
  background-color: transparent;
  border-radius: 3px;
  text-align: center;
  font-family: 'sofia-pro';
  font-size: 14px;
  line-height: 21px;
  color: $black;
  padding-left: 0.9em;

  &:focus {
    border: 1px solid $primary;
    outline: none;
  }
}

/* LINKS & BUTTONS
-------------------------------------------------- */

.uk-link {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: $primary;

  &:hover {
    text-decoration: none;
    color: darken($color: $primary, $amount: 10%);
  }
}

.uk-button {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 20px 16px;
  border-radius: 3px;

  &-small {
    padding: 0 15px;
    line-height: 30px;
    font-size: 0.875rem;
  }
}

.uk-button-primary {
  background-color: $primary;
  color: $white;

  &:hover {
    background-color: darken($color: $primary, $amount: 10%);
  }
}

.uk-button-secondary {
  background-color: $secondary;
  color: $primary;

  &:hover {
    background-color: darken($color: $primary, $amount: 10%);
    color: $white;
  }
}

.uk-button-success {
  background-color: $success-color;
  color: $white;
  display: flex;
  align-items: center;

  .uk-svg {
    color: $white;
    fill: $white;
    margin-top: 3px;

    * {
      fill: $white;
    }
  }

  &:hover {
    background-color: darken($color: $success-color, $amount: 6%);
  }
}

.uk-button-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  background-color: transparent;
  border: 1px solid $lines;

  font-weight: 300;
  color: $black;

  transition: border 0.15s ease-in-out;

  i,
  svg {
    font-size: 12px;
    color: $muted;
    transition: color 0.15s ease-in-out;
  }

  &:hover {
    border: 1px solid $primary;

    i,
    svg {
      color: $primary;
    }
  }
}

.uk-background {
  &-white {
    background-color: $white;
  }
}

.button {
  &__cart {
    width: 50px;
    height: 50px;
    background-color: $success-color;
    color: $white;
    border: 0;
    border-radius: 3px;
    cursor: pointer;

    .uk-svg {
      color: $white;
      fill: $white;

      * {
        fill: $white;
      }
    }

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: darken($color: $success-color, $amount: 6%);
      text-decoration: none;
    }
  }

  &__back {
    color: $muted;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  &__delete {
    background-color: transparent;
    border: 0;
    font-size: 18px;
    color: $lines;
  }
}

/* CART DROPDOWN
-------------------------------------------------- */
.cart-dropdown {
  background-color: $white;
  border: 1px solid $lines;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
  width: 390px;
  padding: 30px;

  > ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      display: flex;
      flex-flow: column;
      padding: 20px 0;
      border-bottom: 1px solid $lines;
    }
  }
}

/* SETTINGS DROPDOWN
-------------------------------------------------- */

.settings-dropdown {
  background-color: $white;
  border: 1px solid $lines;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
  width: 260px;
  padding: 0;

  &--small {
    min-width: 84px;
    width: 84px !important;
  }

  ul {
    display: flex;
    flex-flow: column;

    li {
      a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 50px;
        padding: 0px 30px;
        border-bottom: 1px solid $lines;
        color: $black;

        &:hover {
          color: $primary;
          background-color: $secondary;
        }
      }

      &:last-of-type {
        a {
          border-bottom: 0;
        }
      }
    }
  }
}

/* MODALS
-------------------------------------------------- */

.uk-modal {
  background-color: transparentize($color: $secondary, $amount: 0.05);

  .uk-modal-dialog {
    background-color: $white;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    width: 65vw;
    display: flex;
    position: relative;

    > form {
      width: 100%;
    }

    .image {
      position: absolute;
      top: 0;
      right: 66.66%;
      bottom: 0;
      left: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .content {
      //margin-left: 33.33%;
      padding: 60px;
    }

    #closer {
      border: 0;
      background-color: transparent;
      position: absolute;
      right: 60px;
      top: 60px;
      font-size: 21px;
      color: $lines;
      cursor: pointer;

      &:hover {
        color: $primary;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

/* LISTS
-------------------------------------------------- */

ul.checklist {
  padding: 0;
  margin: 0;
  list-style-type: none;

  li {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 16px;

    > i,
    > svg {
      font-size: 21px;
      flex: 0 0 21px;
      margin-right: 15px;
      color: $success-color;
    }

    p {
      margin: -2px 0 0 0;
    }
  }
}

/* IMAGES
-------------------------------------------------- */

.image-block {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 75%;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  &--wide {
    padding-top: 56%;
  }
}

.button-icon {
  width: 50px;
  height: 50px;
  border-radius: 9999px;
  background-color: $primary;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;

  &--large {
    width: 80px;
    height: 80px;
    font-size: 18px;
  }
}

/* CONTAINERS
-------------------------------------------------- */
.uk-container {
  transition: max-width 0.6s cubic-bezier(0.65, 0, 0.35, 1);
}

/* TABLES
-------------------------------------------------- */

.uk-table {
  &.uk-table-hover {
    > tr:hover,
    tbody tr:hover {
      background-color: $secondary;
      cursor: pointer;

      * {
        color: $primary;
        font-weight: 600;
      }
    }
  }
}

.action {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $black;

  &:hover {
    color: $primary;
  }
}

/* ACCORDIONS
-------------------------------------------------- */

.questions-accordion {
  li {
    margin-bottom: 10px;
    margin-top: 0 !important;

    a.uk-accordion-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 14px 30px;
      background-color: $grey-light;

      font-size: 16px;
      line-height: 24px;
      color: $black;

      transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;

      i,
      svg {
        font-size: 16px;
        transition: transform 0.25s ease-in-out;
      }

      &:hover {
        color: $primary;
        background-color: $secondary;
      }
    }

    .uk-accordion-content {
      padding: 30px;
      margin: 0;
    }

    &.uk-open {
      a.uk-accordion-title {
        background-color: $primary;
        color: $white;

        i,
        svg {
          transform: rotate(90deg);
        }
      }
    }
  }
}

/* OFF CANVAS MENUS
-------------------------------------------------- */

.uk-offcanvas {
  &-overlay.uk-open::before {
    background-color: transparentize($color: $secondary, $amount: 0.1);
  }

  &-bar {
    width: 50vw;
    left: -50vw;
    box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16);
    padding: 0;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    overflow-y: scroll;

    ul.side-menu > li:last-of-type > a {
      border-bottom: 0;
    }

    ul.side-menu:last-of-type {
      flex: 1 0 auto;
      box-shadow: 0px -10px 30px -15px rgba(0, 0, 0, 0.3);

      > li:first-of-type > a {
        border-top: 1px solid $lines;
      }
    }
  }

  &-bar-animation {
    transition: left 0.6s cubic-bezier(0.65, 0, 0.35, 1);
  }
}

/* GLOBAL ALERT
-------------------------------------------------- */

#global-alert {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: $primary;
  z-index: 9999;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;

  button#global-alert-close {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    border: 0;
    background-color: transparent;
    color: $white;
    font-size: 21px;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
}


/* NOTIFICATION
-------------------------------------------------- */

.uk-notification {
  box-sizing: content-box;
  z-index: 9999;
  color: $white;
  width: unset;
  top: 0px;
  display: none;

  &-message {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 75px;
    padding: 0em 2em;
    font-size: 18px;
    font-weight: 300;

    div {
      display: flex;
      align-items: center;
      margin-right: 26px;
    }

    &-success {
      background-color: #18bbb9;
      color: $white;
    }
  }

  &-top-right {
    top: 75px;
    right: 0;
  }

  &-close {
    color: $white;
    top: auto;
    bottom: auto;
  }
}

/* PROMO BANNER
-------------------------------------------------- */

.promo-banner {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 7px 10px;
  background-color: $primary;
  color: $white;
  border-radius: 3px;
  font-family: 'sofia-pro';
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  font-family: "sofia-pro", "Helvetica", "Arial", sans-serif;
}

/* PAGINATION
-------------------------------------------------- */

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  > * {
    margin: 0px 2px;
  }

  &__button {
    padding: 8px 20px;
    border: 1px solid $lines;
    border-radius: 3px;
    height: 40px;

    font-size: 14px;
    line-height: 21px;
    color: $black;

    i,
    svg {
      font-size: 12px;
    }
  }

  &__number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 1px solid $lines;
    border-radius: 3px;

    font-size: 14px;
    line-height: 21px;
    color: $black;

    &--active {
      background-color: $primary;
      color: $white;
      border: 1px solid $primary;
    }
  }

  &__button,
  &__number {
    transition: 0.15s ease-in-out;

    &:hover {
      text-decoration: none;
      background-color: $primary;
      color: $white;
      border: 1px solid $primary;
    }
  }
}

/* STATUSES
-------------------------------------------------- */

.status {
  font-size: 18px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  i,
  svg {
    flex: 0 0 18px;
    margin-right: 10px;
  }

  p {
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
  }

  &--success {
    color: $success-color;

    p {
      color: $success-color;
    }
  }

  &--warning {
    color: $warning-color;

    p {
      color: $warning-color;
    }
  }

  &--danger {
    color: $danger-color;

    p {
      color: $danger-color;
    }
  }
}

/* SCROLL TO TOP BUTTON
-------------------------------------------------- */

a#scroll-top {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  border-radius: 3px 0 0 0;
  border: 0;
  background-color: $primary;
  color: $white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  opacity: 1;
  transition: all .3s ease-in-out;

  &.hidden {
    bottom: -50px;
    opacity: 0;
    transition: all .6s cubic-bezier(0.65, 0, 0.35, 1);
  }

  &:hover {
    background-color: darken($color: $primary, $amount: 10%);
  }
}

/* STEPS
-------------------------------------------------- */

.steps {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .step {
    background-color: $background;
    padding: 0px 30px 0px 50px;
    position: relative;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-radius: 3px;

    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: $black;
    text-decoration: none;

    &:before,
    &:after {
      top: 50%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      border-color: transparent;
      border-width: 25px;
      margin-top: -25px;
    }

    &:after {
      left: 100%;
      border-left-color: $background;
      z-index: 999;
    }

    &:before {
      left: 0;
      border-left-color: $white;
    }

    &:first-of-type {
      padding: 0px 30px 0px 25px;

      &:before {
        display: none;
      }
    }

    &:last-of-type {
      padding: 0px 25px 0px 50px;

      &:after {
        display: none;
      }
    }

    &--current {
      background-color: $primary !important;
      color: $white !important;

      &:after {
        border-left-color: $primary !important;
      }
    }

    &--next {
      &:hover {
        cursor: not-allowed;
        background-color: $lines;

        &:after {
          border-left-color: $lines;
        }
      }
    }

    &--previous {
      background-color: $secondary;
      color: $primary;

      &:after {
        border-left-color: $secondary;
      }

      &:hover {
        background-color: $primary;
        color: $white;

        &:after {
          border-left-color: $primary;
        }
      }
    }
  }
}

/* TABS
-------------------------------------------------- */

.tabs-nav {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  li {
    margin: 0px 1px;
    padding: 0;

    &.uk-active {
      a {
        background-color: $primary;
        color: $white;
        font-weight: 600;
        padding: 10px 14px;
      }
    }

    a {
      font-size: 14px;
      color: $primary;
      background-color: $secondary;
      padding: 10px 15px;
      border-radius: 3px 3px 0 0;
      display: block;
      text-decoration: none;
      transition: 0.15s ease-in-out;

      &:hover {
        background-color: darken($color: $primary, $amount: 6%);
        color: $white;
      }
    }
  }
}

/* CUSTOM DROPDOWN
-------------------------------------------------- */

.custom-dropdown {
  background-color: $white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  border: 1px solid $lines;
  padding: 0;

  ul > li {
    border-bottom: 1px solid $lines;
    padding: 0;

    > a {
      padding: 12px 20px;

      font-size: 16px;
      color: $black;

      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;

      &:hover {
        background-color: $secondary;
        color: $primary;
        text-decoration: none;
      }
    }

    &:last-of-type {
      border-bottom: 0;
    }
  }
}

/* TOGGLE SWITCH
-------------------------------------------------- */

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 22px;

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $lines;
    transition: 0.4s;
    border-radius: 9999px;

    &:before {
      position: absolute;
      content: ' ';
      height: 22px;
      width: 22px;
      left: 0;
      bottom: 0;
      background-color: $white;
      transition: 0.4s;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      border-radius: 9999px;
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked {
      + .slider {
        background-color: $primary;

        &:before {
          transform: translateX(24px);
        }

        &--disabled {
          background-color: $muted;
        }
      }
    }

    &:focus {
      + .slider {
        box-shadow: 0 0 1px $primary;
      }
    }
  }

  &.small {
    width: 32px;
    height: 20px;

    .slider {
      &:before {
        height: 16px;
        width: 16px;
        bottom: 2px;
        left: 2px;
        box-shadow: none;
      }
    }

    input {
      &:checked {
        + .slider {
          &:before {
            transform: translateX(12px);
          }
        }
      }
    }
  }
}
